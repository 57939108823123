<template>
  <div class="module-list__wrapper--course-details">
    <vle-accordion :open.sync="open" @toggle="onToggle" :module.sync="module">
      <template v-slot:accordion-title>
        <div
          class="module-list__header"
          :class="{ 'module-list__header--complete': module.complete }"
        >
          <div class="module-list__header-title">
            {{ module.title }}
          </div>
          <div class="module-list__header-sub-title">
            <small>{{ currentModuleNo }} / {{ totalModules }}</small>
          </div>
        </div>
      </template>
      <template v-slot:accordion-body>
        <div v-for="(sequence, modIndex) in module.sequences" :key="modIndex">
          <div class="module-list__sub-header">
            <div class="module-list__sub-header-title">
              {{ sequence.title }}
            </div>
          </div>

          <div class="module-list__container">
            <div class="module-list__pb-wrapper"></div>
            <ul>
              <li
                v-for="(unit, seqIndex) in sequence.units"
                :key="seqIndex"
                class="module-list__section"
                :class="{
                  'module-list__section--active':
                    getCurrentUnitId === unit.id && isEnable,
                  'module-list__section--completed': unit.complete === true,
                  'module-list__section--locked':
                    isPrerequisiteCompleted(sequence) !== true,
                  'module-list__section--only-child':
                    module.sequences.length == 1,
                  'module-list__section--not-enabled': !isEnable
                }"
                :id="
                  isPrerequisiteCompleted(sequence) !== true
                    ? 'module-list__section--locked'
                    : 'module-list__section'
                "
                @click="selectModule(sequence, unit.id)"
              >
                <div class="d-flex">
                  <div class="sp__module-align align-self-center">
                    <img
                      v-if="isPrerequisiteCompleted(sequence) !== true"
                      :src="
                        require('@/assets/images/module-list/unit-locked.svg')
                      "
                      alt="unit locked"
                    />
                    <img
                      v-else-if="unit.complete"
                      :src="
                        require('@/assets/images/module-list/unit-complete.svg')
                      "
                      alt="unit complete"
                    />
                    <img
                      v-else-if="
                        getCurrentUnitId === unit.id && showCurrentAvatar
                      "
                      :src="
                        allProfile.profile_image_url
                          ? allProfile.profile_image_url
                          : require('@/assets/images/menu/avatar.svg')
                      "
                      alt="avatar"
                      width="24px"
                      height="24px"
                      class="rounded-circle"
                      id="profile-pic"
                    />
                    <img
                      v-else
                      :src="
                        getUnitIcon(
                          unit.type,
                          getCurrentUnitId === unit.id && isEnable
                        )
                      "
                      :alt="unit.type"
                    />
                  </div>
                  <div
                    :id="
                      isPrerequisiteCompleted(sequence) !== true
                        ? `tooltip-target-title-mod${modIndex}-seq${seqIndex}`
                        : ''
                    "
                  >
                    {{ unit.title }}
                    <div class="module-list__type">
                      {{ getUnitType(unit.type) }}
                    </div>
                  </div>
                </div>
                <b-tooltip
                  v-if="getPrerequisiteCompleted(sequence)"
                  :target="`tooltip-target-title-mod${modIndex}-seq${seqIndex}`"
                >
                  {{ $t("course.content_lock_alert") }}
                  {{ getPrerequisiteCompleted(sequence) }}
                </b-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-slot:[dynamicSlotName] v-if="module.complete === true">
        <div class="mb-2 d-flex justify-content-between">
          <div class="lx-section-completed">
            {{ $t("my_learning.tab.completed") }}
          </div>
          <div>
            <img
              src="@/assets/images/course-details/check-circle-fill.svg"
              alt="completed"
            />
          </div>
        </div>
      </template>
    </vle-accordion>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import userLayout from "../SelfPaced/mixins/userLayout";
import VleAccordion from "@/components/WrapperComponents/VleAccordion.vue";
export default {
  mixins: [userLayout],
  props: {
    module: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    currentModuleNo: {
      type: Number,
      required: true
    },
    totalModules: {
      type: Number,
      required: true
    },
    isEnable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  components: { VleAccordion },
  computed: {
    ...mapGetters([
      "getCurrentUnitId",
      "isPrerequisiteCompleted",
      "getCurrentSection",
      "getSelectedCourse",
      "allProfile"
    ]),
    navIconPath() {
      let str = "";
      if (this.module.complete) {
        str = require("@/assets/images/vle/nav/completed.svg");
      } else if (
        this.module.sequences.findIndex(a => a.prerequisite !== null) !== -1 &&
        this.isSectionLocked()
      ) {
        str = require("@/assets/images/vle/nav/lock.svg");
      } else {
        str = require("@/assets/images/vle/nav/inprogress.svg");
      }
      return str;
    },
    dynamicSlotName() {
      return this.open
        ? "accordion-complete-no-collapse-icon"
        : "accordion-complete-collapse-icon";
    },
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    showCurrentAvatar() {
      return this.isAuthenticated && this.getSelectedCourse.is_enrolled;
    }
  },
  watch: {
    isOpen() {
      this.open = this.isOpen;
    }
  },
  mounted() {
    this.open = this.isOpen;
  },
  methods: {
    getPrerequisiteCompleted(module) {
      let title = null;
      if (module && module.prerequisite && module.prerequisite !== null) {
        // Loop through all the sequences across all sections
        // and find the completion status of the prerequisite
        // sequence
        let isCompleted = this.getSelectedCourse.sections.some(m => {
          return m.sequences.some(s => {
            if (s.id === module.prerequisite) {
              title = s.title;
              return s.complete;
            }
          });
        });
        if (isCompleted) {
          return title;
        }
      }
      return title;
    },
    selectModule(module, unitId) {
      if (
        this.isPrerequisiteCompleted(module) === true &&
        this.open &&
        this.isEnable
      ) {
        // update the current section for micro-credential check
        this.$store.commit("SET_MODULE_TO_CHECK_MC", this.getCurrentSection);

        this.$store.commit("SET_CURRENT_UNIT_ID", unitId);
        this.$store.commit("SET_CURRENT_SEQUENCE_ID", module.id);
        this.$router
          .replace({ path: `/self-paced/${this.$route.params.id}` })
          .catch(() => {});
      }
    },
    onToggle(bool) {
      this.open = bool;
    },
    isSectionLocked() {
      let bool = false;
      this.module.sequences.forEach(a => {
        if (this.isPrerequisiteCompleted(a) !== true) {
          bool = true;
        }
      });
      return bool;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/wrapper-components/tooltip";
.module-list__wrapper--course-details {
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 2px 2.5px 11px 3px rgba(0, 129, 255, 0.15);

  .module-list__container {
    padding: 5px 0;
    text-align: left;
    position: relative;

    .module-list__pb-wrapper {
      position: absolute;
      height: 100%;
      top: -4px;
    }

    ul {
      padding: 12px 0px;
      list-style: none;
      margin: 0;
    }

    .module-list__type {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: $brand-neutral-200;
    }
    .module-list__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $vle-primary-text;
      padding: 0 15px 0 10px;
      @include line-clamp;
    }
    .module-list__section:hover {
      background: $brand-primary-100;
    }

    .module-list__section {
      @include body-medium;
      color: $brand-neutral-700;
      min-height: 55px;
      border-radius: 8px;
      padding: 8px 16px;
      span {
        @include line-clamp;
        padding-right: 16px;
      }
      .sp__module-align {
        margin-right: 10px;
      }
      &.module-list__section--active {
        font-weight: 700;
        background: $brand-primary;
        color: #f9fcff;
      }

      &.module-list__section--not-enabled {
        background: none;
        cursor: default;
      }

      &.module-list__section--completed {
        span {
          background: none;
        }
      }

      &.module-list__section--locked {
        color: $brand-disabled-grey;
        cursor: not-allowed;
        &:hover {
          background: $brand-neutral-75;
          cursor: not-allowed;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
    .module-list__section + .module-list__section {
      margin-top: 20px;
    }
  }
  .module-list__header {
    &.module-list__header--complete {
      img {
        width: 14px;
        margin-left: 2px;
      }
    }
    img {
      margin-left: 1px;
      width: 16px;
      height: 16px;
    }
    .b-icon {
      font-size: 16px;
    }
    .module-list__header-title {
      text-align: left;
      @include subtitle-regular;
      color: $vle-primary-text;
    }
    .module-list__header-sub-title {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 12px;
      color: $brand-default-text;
      letter-spacing: 0.15px;
    }
  }
  .lx-section-completed {
    @include label-medium;
    font-weight: 500;
    color: #00c781;
  }

  .module-list__sub-header-title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: $brand-neutral-700;
  }
}

@media screen and (max-width: 480px) {
  .module-list__wrapper--course-details {
    margin: 16px 0px 8px 0px;
  }
}

[dir="rtl"] {
  .module-list__wrapper--course-details {
    .module-list__container {
      .module-list__section {
        text-align: right;
        .sp__module-align {
          margin-left: 8px;
        }
        span {
          padding-right: 0;
        }
      }
    }
    .module-list__header-div {
      text-align: right;
      padding: 0 1rem 0 0;
    }
    .module-list__header-title {
      text-align: right;
    }
  }
}
</style>
